* {
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
}

*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  font-size: 62.5%;
  margin: 0;
  padding: 0;
  max-width: 100%;
  overflow-x: hidden;
}
body {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 1.7;
  color: 'black';

  box-sizing: border-box;
  scroll-behavior: smooth;
}
a {
  text-decoration: none;
}
.app {
}
