.home {
  display: grid;
  grid-template-columns: 50rem 1fr;
  grid-template-rows: 1rem 75rem 35rem calc(100vh - 80rem) 10rem;
  @media (max-width: 1181px) {
    grid-template-columns: 30rem 1fr;
  }
  @media (max-width: 958px) {
    grid-template-columns: 0 100%;
    grid-template-rows: auto 75rem 35rem calc(100vh - 80rem) 10rem;
  }
  @media (max-width: 700px) {
    grid-template-rows: auto auto auto;
    grid-template-columns: 0 100%;
  }
  &-background {
    grid-column: 1/-1;
    grid-row: 1/-1;
    background-image: linear-gradient(
        151deg,
        rgba(0, 0, 0, 0.25) 0%,
        rgba(0, 0, 0, 0.27) 100%
      ),
      url("../../assets/background-1-min\ \(1\).png");
    background-repeat: no-repeat;
    background-size: cover;
    filter: grayscale(1);
    transition: all 0.73s;
  }
  &-right {
    grid-column: 2/3;
    grid-row: 1/-1;
    z-index: 45453455443;
    @media (max-width: 958px) {
      grid-column: 2/3;
      grid-row: 2/-1;
    }
  }
  &-left {
    grid-column: 1/2;
    grid-row: 1/-1;
    height: 100%;
    width: 100%;
    background: rgba(3, 3, 81, 0.753);
    z-index: 4;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 25rem 35rem 1fr;

    @media (max-width: 958px) {
      grid-column: 1/-1;
      grid-row: 1/2;

      grid-template-columns: 0 1fr;
      grid-template-rows: 100%;
    }
    @media (max-width: 700px) {
      grid-template-columns: 0 1fr;
      grid-template-rows: auto;
    }

    &-img {
      height: 20rem;
      width: 20rem;
      border-radius: 54rem;
      justify-self: center;
      align-self: center;

      @media (max-width: 958px) {
        display: none;
      }
    }
    &-text {
      justify-self: center;
      align-self: start;
      grid-row: 2/3;
      color: #fff;
      text-align: center;
      @media (max-width: 958px) {
        grid-column: 2/3;
        grid-row: 1/2;

        justify-self: start;
        align-self: center;
        width: 100%;
      }

      & h4 {
        font-size: 5.5rem;
        font-weight: 100;
        margin-bottom: -1rem;

        @media (max-width: 958px) {
          font-size: 2.2rem;
        }
      }
      & h6 {
        text-align: center;
        font-size: 3rem;
        @media (max-width: 958px) {
          font-size: 2.2rem;
        }
      }
      & h2 {
        margin-top: 1rem;
        text-align: center;
        font-size: 3rem;
        @media (max-width: 958px) {
          font-size: 2.2rem;
        }
      }
    }
  }
}

#left:hover ~ #background {
  filter: grayscale(0);
  transition: all 0.73s;
}

.social {
  &_box {
    display: flex;
    justify-content: space-around;
    margin-top: 1rem;
    @media (max-width: 958px) {
      font-size: 2.2rem;
    }
    @media (max-width: 700px) {
      justify-content: center;
    }
  }
  &_icon {
    height: 5rem;
    width: 5rem;
    fill: #fff;
    opacity: 0.7;
    transition: all 0.3s;

    @media (max-width: 958px) {
      height: 3.5rem;
      width: 3.5rem;
      margin-right: 1rem;
    }
    @media (max-width: 700px) {
    }

    &:hover {
      opacity: 1;
    }
  }
}

.mobile {
  display: none;
  @media (max-width: 958px) {
    display: flex;
    justify-content: space-around;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  @media (max-width: 700px) {
    margin-top: 0.51rem;
    margin-bottom: 0.51rem;
  }

  &-left {
    display: flex;
    justify-self: center;
    align-content: center;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    justify-self: center;
    align-self: center;

    & h2 {
      margin-top: -0.51rem;
    }
  }
  &-img {
    display: none;
    @media (max-width: 958px) {
      display: block;
      border-radius: 100rem;
      height: 17rem;
      width: 17rem;
    }
    @media (max-width: 700px) {
      display: block;
      border-radius: 100rem;
      height: 10rem;
      width: 10rem;
    }
  }
}
.website {
  @media (max-width: 958px) {
    display: none;
  }
}

#display {
  display: none;
  @media (max-width: 958px) {
    display: flex;
  }
}
