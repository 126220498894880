.projectPage {
  color: black;
  display: grid;
  grid-template-columns: 5rem 1fr 5rem 5rem;
  grid-template-rows: 15rem auto 1fr 25rem 1fr;

  @media (max-width: 1500px) {
    grid-template-columns: 5rem 1fr 1rem;
    grid-template-rows: 15rem auto 1fr;
  }
  @media (max-width: 1335px) {
    grid-template-columns: 5rem 1fr 1rem;
    grid-template-rows: 15rem auto 1fr;
  }

  & h2 {
    position: relative;
    font-size: 6rem;
  }
  &-header {
    margin-left: 7rem;
    @media (max-width: 900px) {
      margin-left: 1rem;
      width: 90%;
    }
  }

  &-1 {
    grid-column: 1/3;
    grid-row: 2/3;

    display: flex;
    justify-content: space-around;
    margin-bottom: 5rem;
    @media (max-width: 900px) {
      grid-row: 3/4;
      grid-column: 1/3;
    }
    @media (max-width: 500px) {
      justify-content: center;
    }

    & img {
    }
  }
  &-text {
    width: 50%;
    font-size: 2.3rem;
    text-align: justify;
    margin: 2rem 1rem;
    @media (max-width: 1750px) {
      width: 40%;
      font-size: 2.3rem;
      text-align: justify;
      margin: 2rem 1rem;
    }

    @media (max-width: 1500px) {
      width: 40%;
      line-height: 2.4rem;
      font-size: 2.3rem;
      text-align: justify;
      margin: 2rem 1rem;
    }
    @media (max-width: 900px) {
      width: 90%;
      line-height: 2.4rem;
      font-size: 2.3rem;
      text-align: justify;
      margin: 2rem 1rem;
    }
    @media (max-width: 900px) {
      margin: 0.5rem;
    }
    @media (max-width: 700px) {
      width: 100%;
    }
    @media (max-width: 500px) {
      width: 100%;
    }
  }
  &-link {
    font-size: 3rem;
    font-weight: bold;
    color: black;

    &:active {
      color: black;
    }
  }
  &-tools {
    margin-top: 2rem;
    display: flex;
    flex-wrap: wrap;

    & svg {
      height: 15rem;
      width: 15rem;
      margin-right: 5rem;

      @media (max-width: 450px) {
     height: 10rem;
      width: 10rem;
  }
    }
  }
  &-header {
    &-1 {
      background: rgba(3, 3, 81, 0.753);
      grid-column: 1/-1;
      grid-row: 1/2;
      display: flex;
    }
    &-text {
      font-size: 6.5rem;
      color: #fff;
      align-self: center;
      justify-self: center;
      margin: 0 auto;

      @media (max-width: 700px) {
        font-size: 4rem;
      }
    }
  }
  &-footer {
    grid-column: 1/-1;
    grid-row: 4/-1;
    background-color: black;
    display: flex;
    align-items: center;
    justify-content: center;

    & a {
      cursor: pointer;
      margin-right: 3rem;
      opacity: 0.7;

      &:hover {
        opacity: 1;
      }

      & svg {
        height: 5rem;
        width: 5rem;
        fill: "white";
      }
    }
  }
}
.javascript {
}
.-header-1 {
  grid-column: 2/3;
  grid-row: 1/2;
  height: 7rem;
  width: 7rem;
  background-color: red;
}
.back-btn {
  height: 8rem;
  width: 8rem;
  border-radius: 11rem;
  background-color: rgba(96, 96, 96, 0.515);
  justify-self: start;
  align-self: center;
  margin-left: 8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.7;

  @media (max-width: 700px) {
    margin-left: 2rem;
    height: 5rem;
    width: 5rem;
  }
  &:hover {
    opacity: 1;
  }
  & svg {
    height: 5rem;
    width: 5rem;
    margin-top: 0.53rem;
    margin-right: 0.4rem;
    @media (max-width: 700px) {
      height: 3.5rem;
      width: 3.5rem;
    }
  }
}

.appPic {
  height: 75rem;
  width: 45rem;
  border-radius: 1rem;
  margin-top: 1rem;
  margin-right: 5rem;
  grid-column: 2/3;
  grid-row: 2/3;
  justify-self: end;
  align-self: start;
  @media (max-width: 1500px) {
    height: 75rem;
    width: 45rem;
  }
  @media (max-width: 1335px) {
  }
  @media (max-width: 900px) {
    grid-column: 1/-1;

    margin: 0;
    justify-self: center;
    align-self: center;
    height: 30rem;
    width: 15rem;
  }
}
.websitePic {
  grid-row: 2/3;
  grid-column: 2/3;
  justify-self: end;
  height: 45rem;
  width: 75rem;
  border-radius: 1rem;
  margin-top: 4rem;
  @media (max-width: 1500px) {
    height: 45rem;
    width: 65rem;
  }
  @media (max-width: 1335px) {
    height: 45%;
    width: 45%;
  }
  @media (max-width: 900px) {
    grid-column: 1/-1;
    justify-self: center;
    height: 25rem;
    width: 35rem;
    margin: 0;
    border-radius: 0;
  }
}
