.projects {
  grid-column: 2/3;
  grid-row: 1/3;
  z-index: 4343;
  margin-top: 1rem;
  margin-left: 5rem;

  @media (max-width: 958px) {
    margin-left: 3rem;
  }

  @media (max-width: 700px) {
    margin-top: .1rem;


    
  }
  @media (max-width: 530px) {
    margin-bottom: -10rem;


    
  }

  & h2 {
    font-size: 6rem;
    color: rgb(255, 255, 255);

    position: relative;
    
    @media(max-width: 450px){
      margin-top: -1rem;
      font-size: 5rem;
    }
  }
  &_container {
    width: 100%;
    margin-top: 3rem;
    margin-bottom: 4rem;
    display: flex;
    flex-wrap: wrap;
    // justify-content: space-around;
  }

  &_box {
    height: 100%;
    width: 100%;

    box-shadow: -6px 8px 9px 6px rgba(0, 0, 0, 0.43);
    background-color: black;
    margin: 2rem;

    &-image {
      height: 100%;
      width: 100%;

      -webkit-user-drag: none;
      user-select: none;
      -moz-user-select: none;
      -webkit-user-select: none;
      -ms-user-select: none;

      
    }
    &-link {
      position: absolute;
      z-index: 99999999999;
    }
  }
}
.react-horizontal-scrolling-menu--wrapper {
  flex-wrap: wrap;
  justify-content: center;
}

.react-horizontal-scrolling-menu--scroll-container {
  flex-basis: 100%;
  flex-shrink: 0;
}

/* Change position of container so arrows will be at top/bottom */
// .react-horizontal-scrolling-menu--scroll-container {
//   order: -1; /* order: 1; for top position */
// }

.react-horizontal-scrolling-menu--wrapper {
  flex-wrap: wrap;
  justify-content: center;
}

.react-horizontal-scrolling-menu--scroll-container {
  order: 1;
  flex-basis: 100%;
  flex-shrink: 0;
}

* {
  box-sizing: border-box;
}
.react-horizontal-scrolling-menu--scroll-container::-webkit-scrollbar {
  display: none;
}

.react-horizontal-scrolling-menu--scroll-container {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}